// 出版书籍
<template>
  <div class="honour-publishingBooks">
    <el-row class="boks-list">
      <el-col class="books-item" :span="20" v-for="item in books" :key="item.id"
        :style="{backgroundColor:item.content}">
        <el-image class="books-img" :src="$base+item.picture" alt=""></el-image>
        <div class="books-info">
          <h3 class="info-title">{{item.name}}</h3>
          <p class="info-text">{{item.describe}}</p>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getCore } from '@/api'
export default {
  name: 'PublishingBooks',

  data () {
    return {
      books: []
    };
  },

  mounted () {
    this.getData()
  },

  methods: {
    async getData () {
      let { data } = await getCore('sjcb')
      this.books = data
    }
  },

};
</script>

<style scoped lang="less">
.books-item {
  height: 153px;
  border-radius: 76px;
  position: relative;
  margin: 50px;
  .books-img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -10px;
  }
  .books-info {
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translateY(-50%);
    color: #fff;
    .info-title {
      font-size: 30px;
      margin-bottom: 22px;
    }
  }
}
</style>
